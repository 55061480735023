import { readContract } from 'wagmi/actions';

import { IDelegationManagerAbi } from '@layr-labs/eigen-kit/abi';
import { CurrentRestaked } from '@layr-labs/eigen-kit/types';

import { config } from 'chain/Web3Provider';
import { stakeConfig } from '@/config/config';

export const getCurrentRestaked = async (address: `0x${string}`): Promise<CurrentRestaked> => {
  try {
    const currentRestaked = await readContract(config, {
      address: stakeConfig.delegationManagerAddress as `0x${string}`,
      abi: IDelegationManagerAbi,
      functionName: 'getDelegatableShares',
      args: [address],
    });
    return {
      shares: currentRestaked[1],
      strategies: currentRestaked[0],
    };
  } catch (error) {
    throw new Error('Failed to fetch currentRestaked');
  }
};
