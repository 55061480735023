import { useReadContract } from 'wagmi';

import { IEigenPodManagerAbi } from '@layr-labs/eigen-kit/abi';

import { stakeConfig } from '@/config';

import useAccount from './useAccount';

export default function usePodOwnerShares() {
  const { address } = useAccount();

  return useReadContract({
    address: stakeConfig.eigenPodManagerAddress as `0x${string}`,
    abi: IEigenPodManagerAbi,
    functionName: 'podOwnerShares',
    args: address && [address],
    query: {
      enabled: !!address,
    },
  });
}
