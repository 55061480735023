import { Address } from 'viem';

import { api } from '@/utils/api';

import { env } from '@env';

const useAccess = (address?: Address) => {
  return api.access.getAccess.useQuery(
    { address: address },
    {
      staleTime: 60 * 60 * 1000,
      gcTime: 60 * 60 * 1000,
      enabled: !!address && !!env.NEXT_PUBLIC_SANCTIONS_ON,
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    },
  );
};

export default useAccess;
