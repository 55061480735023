'use client';

import { useQuery } from '@tanstack/react-query';
import { Address } from 'abitype';
import { isEmpty } from 'lodash';
import { ContractFunctionParameters } from 'viem';
import { multicall } from 'wagmi/actions';

import { IStrategyAbi } from '@layr-labs/eigen-kit/abi';
import { token } from '@layr-labs/eigen-kit/types';

import { config } from 'chain/Web3Provider';
import { ZERO_ADDRESS } from '@/utils/constants';

type SharesToUnderlying = {
  strategy: Address;
  sharesToUnderlying: bigint;
};

const useQuerySharesToUnderlyingFactor = ({ tokens = [] }: { tokens: token[] }) =>
  useQuery({
    queryKey: ['getSharesToUnderlyingFactor', tokens],
    queryFn: async () => {
      const filteredList = tokens.filter(({ strategyAddress }) => strategyAddress !== ZERO_ADDRESS);

      try {
        const result = await multicall(config, {
          contracts: tokens
            .filter(
              ({ strategyAddress }) =>
                strategyAddress !== ZERO_ADDRESS && !isEmpty(strategyAddress),
            )
            .map(
              ({ strategyAddress }) =>
                ({
                  address: strategyAddress,
                  abi: IStrategyAbi,
                  functionName: 'sharesToUnderlyingView',
                  args: [1000000000000000000n],
                }) as ContractFunctionParameters<
                  typeof IStrategyAbi,
                  'view',
                  'sharesToUnderlyingView'
                >,
            ),
          allowFailure: false,
        }).then((res) =>
          res.map((sharesToUnderlying, idx) => ({
            strategy: filteredList[idx].strategyAddress,
            sharesToUnderlying,
          })),
        );

        if (tokens.some((token) => token.strategyAddress === ZERO_ADDRESS)) {
          result.push({
            strategy: ZERO_ADDRESS,
            sharesToUnderlying: 1000000000000000000n,
          });
        }

        return (result.length > 0 ? result : []) as SharesToUnderlying[];
      } catch (e) {
        return [];
      }
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });

export default useQuerySharesToUnderlyingFactor;
