import { getCurrentRestaked } from '@/wagmi';
import { useQuery } from '@tanstack/react-query';

import useAccount from './useAccount';

const useFetchTokenSharesStrats = () => {
  const { address } = useAccount();
  return useQuery({
    queryKey: ['tokenSharesStrats', address],
    queryFn: async () => (address ? await getCurrentRestaked(address) : undefined),
    staleTime: 6000,
    refetchOnWindowFocus: true,
    enabled: !!address,
  });
};

export default useFetchTokenSharesStrats;
