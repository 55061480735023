'use client';

import { useEffect, useMemo, useState } from 'react';

import useAccount from '@/hooks/useAccount';
import useGlobalPodSummary from '@/hooks/useGlobalPodSummary';
import { useInvalidateOnBlockNumber } from '@/hooks/useOnBlockNumber';
import usePodOwnerShares from '@/hooks/usePodOwnerShares';

import { stakeConfig } from '@/config';

import { Token } from '../classes/token';
import { usePrice } from './usePrices';

const NATIVE_TOKEN = stakeConfig.nativeToken;

const useNativeToken = () => {
  const { address } = useAccount();

  const podOwnerShares = usePodOwnerShares();

  useInvalidateOnBlockNumber({ queryKey: podOwnerShares.queryKey });

  const {
    data: priceData,
    isLoading: isPriceDataLoading,
    isSuccess: isPriceDataSuccess,
    error: priceDataError,
  } = usePrice({ version: 'v0', address: 'ethereum' });

  const {
    data: globalPodSummary,
    isLoading: isGlobalPodSummaryLoading,
    isSuccess: isGlobalPodSummarySuccess,
    error: globalPodSummaryError,
  } = useGlobalPodSummary();

  return useMemo(() => {
    let token: Token | null = null;
    const isLoading = isPriceDataLoading || isGlobalPodSummaryLoading;
    const isSuccess = isPriceDataSuccess && isGlobalPodSummarySuccess;
    const error = priceDataError || globalPodSummaryError;

    if (isSuccess) {
      token = new Token(
        { ...NATIVE_TOKEN, slug: 'ETH' },
        {
          icon: NATIVE_TOKEN.icon,
          price: priceData.usd,
          marketCap: priceData.usd_market_cap,
          sharesToUnderlyings: [
            {
              strategy: NATIVE_TOKEN.strategyAddress,
              sharesToUnderlying: 1000000000000000000n,
            },
          ],
          userAddress: address,
          globalPodSummary: globalPodSummary.summary,
          podOwnerShares: podOwnerShares?.data ?? 0n,
        },
      );
    }

    return {
      data: token,
      isLoading: isLoading,
      isSuccess: isSuccess,
      error,
    };
  }, [
    isPriceDataLoading,
    isGlobalPodSummaryLoading,
    isPriceDataSuccess,
    isGlobalPodSummarySuccess,
    priceDataError,
    globalPodSummaryError,
    priceData?.usd,
    priceData?.usd_market_cap,
    address,
    globalPodSummary?.summary,
    podOwnerShares?.data,
  ]);
};

export default useNativeToken;
