import { Address } from 'abitype';

import { TokenPrices } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';

import { env } from '@env';

export const getPriceDataFromIdentifier = (
  token: Partial<{ address: string; apiID: string | null; mainnetAddress: string }>,
  tokenPrices: TokenPrices,
  version: typeof env.NEXT_PUBLIC_LONGTAIL_VERSION = 'v1',
): {
  usd: number;
  eth: number;
  usd_market_cap: number;
  eth_market_cap: number;
} => {
  if (version === 'v0' && token.apiID && token.apiID in tokenPrices) {
    return tokenPrices[token.apiID];
  }

  if (version === 'v1' && token.address && token.address in tokenPrices) {
    return tokenPrices[token.address];
  }

  if (version === 'v1' && token.mainnetAddress && token.mainnetAddress in tokenPrices) {
    return tokenPrices[token.mainnetAddress];
  }

  return {
    usd: 0,
    eth: 0,
    usd_market_cap: 0,
    eth_market_cap: 0,
  };
};

export default function usePrices({
  version = 'v1',
}: {
  version?: typeof env.NEXT_PUBLIC_LONGTAIL_VERSION;
}) {
  return api.price.getPrices.useQuery<{ tokenPrices: TokenPrices }>(
    { version },
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    },
  );
}

export function usePrice({
  address,
  version = 'v1',
}: {
  address: Address | string;
  version?: typeof env.NEXT_PUBLIC_LONGTAIL_VERSION;
}) {
  return api.price.getPrice.useQuery<{
    usd: number;
    eth: number;
    usd_market_cap: number;
    eth_market_cap: number;
  }>(
    { address, version },
    {
      enabled: !!address,
      staleTime: 60000,
      refetchOnWindowFocus: false,
    },
  );
}
