import { GlobalPodSummary } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';

export default function useGlobalPodSummary() {
  return api.native.globalPodSummary.useQuery<{
    summary: GlobalPodSummary;
  }>(
    {},
    {
      staleTime: 12500,
      refetchOnWindowFocus: false,
    },
  );
}
