import { useEffect } from 'react';

import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useBlockNumber } from 'wagmi';

import { SUBGRAPH_TYPES } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';

interface UseOnBlockNumberProps {
  onBlockNumber: (blockNumber?: bigint) => void;
  blockFrequency?: number;
}

interface UseInvalidateOnBlockNumberProps {
  queryKey: QueryKey;
  blockFrequency?: number;
}

interface UseOnSubgraphBlockNumberProps {
  onBlockNumber: (blockNumber?: bigint) => void;
  blockFrequency?: number;
  subgraph: (typeof SUBGRAPH_TYPES)[number];
  query?: Partial<{
    refetchInterval: number;
    retry: number;
    retryDelay: number;
  }>;
}

interface UseInvalidateOnSubgraphBlockProps {
  queryKey: QueryKey;
  blockFrequency?: number;
  subgraph: (typeof SUBGRAPH_TYPES)[number];
}

const useOnBlockNumber = ({ onBlockNumber, blockFrequency = 1 }: UseOnBlockNumberProps) => {
  const { data: blockNumber } = useBlockNumber({ watch: true });

  useEffect(() => {
    if (blockNumber && blockNumber % BigInt(blockFrequency) === BigInt(0)) {
      onBlockNumber(blockNumber);
    }
    // Not adding `onBlockNumber` to the dependencies array as it doesn't
    // actually change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber, blockFrequency]);
};

const useOnSubgraphBlockNumber = ({
  onBlockNumber,
  blockFrequency = 1,
  subgraph,
  query,
}: UseOnSubgraphBlockNumberProps) => {
  const { data: blockNumber } = api.subgraph.getBlockHead.useQuery(
    {
      subgraph,
    },
    {
      refetchInterval: 10_000,
      ...query,
    },
  );

  useEffect(() => {
    if (blockNumber && blockNumber % BigInt(blockFrequency) === BigInt(0)) {
      onBlockNumber(blockNumber);
    }
    // Not adding `onBlockNumber` to the dependencies array as it doesn't
    // actually change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber]);
};

// NOTE: Invalidate on blockhead from the RPC
export const useInvalidateOnBlockNumber = ({
  queryKey,
  blockFrequency = 1,
}: UseInvalidateOnBlockNumberProps) => {
  const queryClient = useQueryClient();

  useOnBlockNumber({
    onBlockNumber: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    blockFrequency,
  });
};

// NOTE: Invalidate on blockhead from the Backend Subgraph
export const useInvalidateOnSubgraphBlock = ({
  queryKey,
  blockFrequency = 1,
  subgraph,
}: UseInvalidateOnSubgraphBlockProps) => {
  const queryClient = useQueryClient();

  useOnSubgraphBlockNumber({
    onBlockNumber: () => {
      queryClient.invalidateQueries({ queryKey });
    },
    blockFrequency,
    subgraph,
  });
};

export default useOnBlockNumber;
