import { StoreTransaction, TransactionStatus } from '@layr-labs/eigen-kit/types';

export const ZERO_ADDRESS: `0x${string}` = '0x0000000000000000000000000000000000000000';
export const BEACON_CHAIN_ETH_STRATEGY_ADDRESS: `0x${string}` =
  '0xbeaC0eeEeeeeEEeEeEEEEeeEEeEeeeEeeEEBEaC0';
export const BIG_NUMBER_MAX = 0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffn;
export const ETH_TO_WEI = 1000000000000000000n;

export const VALIDATORS_PER_CHECKPOINT_TXN = 80;

export const VALIDATORS_PER_CREDENTIAL_TXN = 60;

export const NEW_STORE_TRANSACTION: StoreTransaction = {
  status: TransactionStatus.NotStarted,
  blockNumber: null,
  hash: null,
} as const;

export const VALIDATOR_DOCS_URL =
  'https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/native-restaking/create-eigenpod-and-set-withdrawal-credentials/repointing-a-validators-withdrawal-credentials';
