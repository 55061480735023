import { Address } from 'abitype';
import { camelCase, isArray, isObject, transform } from 'lodash';
import {
  Abi,
  ContractEventName,
  decodeEventLog,
  Hash,
  hexToBytes,
  keccak256,
  recoverPublicKey,
  toBytes,
  TransactionReceipt,
} from 'viem';

import { IDelegationManagerAbi } from '@layr-labs/eigen-kit/abi';
import { isEqualAddress } from '@layr-labs/eigen-kit/util';
import { TransactionStatus } from '@layr-labs/eigen-kit/types';

import { Token } from 'classes/token';
import { stakeConfig } from '@/config';

import { fetchDelayBlocksForTokens } from './uncompletedWithdrawals';

import type { StoreTransaction, token, UncompletedWithdrawal } from '@layr-labs/eigen-kit/types';

export const camelize = (obj = {}) =>
  transform<JSON, JSON>(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

function hashMessage(message: string): `0x${string}` {
  const prefix = `\x19Ethereum Signed Message:\n${message.length}${message}`;
  return keccak256(toBytes(prefix));
}

export async function isValidSignature(
  address: `0x${string}`,
  message: string,
  messageSignature: `0x${string}`,
) {
  const hash = hashMessage(message);

  if (!address || address.length !== 42) {
    console.log('Invalid address');
    return {
      pubKeyHex: address,
      messageHash: hash,
    };
  }

  try {
    const recoveredPubKey = await recoverPublicKey({
      hash,
      signature: messageSignature,
    });

    let recoveredPubKeyBytes = hexToBytes(recoveredPubKey);

    // If uncompressed public key (65 bytes with '04' prefix), remove first byte
    recoveredPubKeyBytes =
      recoveredPubKeyBytes.length === 65 ? recoveredPubKeyBytes.slice(1) : recoveredPubKeyBytes;

    const recoveredAddress = `0x${keccak256(recoveredPubKeyBytes).slice(-40)}`;

    if (recoveredAddress.toLowerCase() !== address.toLowerCase()) {
      console.log('Invalid signature');

      return {
        pubKeyHex: address,
        messageHash: hash,
      };
    }

    return {
      pubKeyHex: recoveredPubKey,
      messageHash: hash,
    };
  } catch (error) {
    console.log('Error recovering public key:', error);
    return {
      pubKeyHex: address,
      messageHash: hash,
    };
  }
}

export const isLSTorNative = (t: token) => {
  return t.group === 'lst' || t.group === 'native' ? true : false;
};

export const isToken = (t: unknown): t is Token => {
  if (!t) {
    return false;
  }

  if (typeof t !== 'object') {
    return false;
  }

  if ('address' in t) {
    return true;
  }

  return false;
};

export const tokenAddressToToken = (address: Address): token | undefined => {
  const tokenList = [...stakeConfig.rewardsTokenList, ...stakeConfig.stakingTokenList];

  return tokenList.find((t) => isEqualAddress(t.address, address));
};

/**
 * Resets a "transaction" in a store to its base state if the passed result
 * is one of failure.
 * @param transaction - The transaction to reset
 * @returns Either a whole transaction or a partial transaction with the
 * status (depending on what it was passed)
 */
export const resetTransactionInStoreIfFailed = <
  T extends Partial<StoreTransaction> | Partial<StoreTransaction>[],
>(
  transaction: T,
) =>
  Array.isArray(transaction)
    ? transaction.map(resetTransactionInStoreIfFailed)
    : ({
        ...transaction,
        status:
          transaction.status === TransactionStatus.Failed
            ? TransactionStatus.NotStarted
            : transaction.status,
      } as T);

/**
 * Returns all matching events from a transaction receipt
 * @param txReceipt The transaction receipt to search
 * @param address The address of the contract that emitted the event
 * @param eventName The name of the event to search for
 * @param abi The ABI of the contract that emitted the event
 * @returns An array of all matching events
 */
export const getEventsFromTransactionReceipt = async <
  const TAbi extends Abi | readonly unknown[],
  TEventName extends ContractEventName<TAbi> | undefined = undefined,
>({
  txReceipt,
  address,
  eventName,
  abi,
}: {
  txReceipt?: TransactionReceipt;
  address: string;
  abi: TAbi;
  eventName: TEventName;
}) => {
  const events: ReturnType<typeof decodeEventLog<TAbi, TEventName>>[] = [];

  if (!txReceipt?.logs) {
    return events;
  }

  txReceipt?.logs?.forEach((log) => {
    if (!isEqualAddress(log.address, address)) {
      return;
    }

    try {
      const event = decodeEventLog({
        abi,
        data: log.data,
        topics: log.topics,
        eventName,
      });

      if (event.eventName === eventName) {
        events.push(event);
      }
    } catch (e) {
      // The event wasn't found in the ABI
    }
  });

  return events;
};

/**
 * Returns all withdrawal roots from a transaction receipt
 * @param txReceipt The transaction receipt to search
 * @returns An array of all withdrawal roots from the transaction receipt
 */
export const getCompleteWithdrawalRootsFromTxReceipt = async (txReceipt?: TransactionReceipt) => {
  const withdrawalRoots: Hash[] = [];

  const events = await getEventsFromTransactionReceipt({
    abi: IDelegationManagerAbi,
    address: stakeConfig.delegationManagerAddress,
    eventName: 'WithdrawalCompleted',
    txReceipt,
  });

  withdrawalRoots.push(...events.map((event) => event.args.withdrawalRoot));

  return withdrawalRoots;
};

/**
 * Returns all queued withdrawals from a `queueWithdrawal` TransactionReceipt
 * @param txReceipt The transaction receipt to search
 * @returns An array of all withdrawals within the transaction's events
 */
export const getWithdrawalsFromTxReceipt = async ({
  txReceipt,
  isUndelegationQueue,
  token,
}: {
  txReceipt?: TransactionReceipt;
  isUndelegationQueue: boolean;
  token: Token;
}) => {
  const withdrawalsQueued: UncompletedWithdrawal[] = [];

  const delayBlocksForTokensPromise = fetchDelayBlocksForTokens([token]);

  const events = await getEventsFromTransactionReceipt({
    abi: IDelegationManagerAbi,
    address: stakeConfig.delegationManagerAddress,
    eventName: 'WithdrawalQueued',
    txReceipt,
  });

  const delayBlocksForTokens = await delayBlocksForTokensPromise;

  withdrawalsQueued.push(
    ...events.map((event) => ({
      ...event.args.withdrawal,
      withdrawalRoot: event.args.withdrawalRoot,
      isUndelegationQueue,
      shares: event.args.withdrawal.shares.map(BigInt),
      strategies: event.args.withdrawal.strategies.map((s) => s as Address),
      completableBlock:
        event.args.withdrawal.startBlock + Number(delayBlocksForTokens[0].withdrawalDelayBlocks),
    })),
  );

  return withdrawalsQueued;
};

/**
 * BigInt utility functions
 */
export const BigIntUtils = {
  /**
   * Returns the maximum value from an array of numbers and bigints
   * @param values bigint or number combined into an array using the rest operator (`...values`)
   * @returns the maximum value from the array
   */
  max: (...values: (bigint | number)[]): bigint =>
    BigInt(values.length < 2 ? values[0] : values.reduce((a, b) => (a > b ? a : b))),
  /**
   * Returns the minimum value from an array of numbers and bigints
   * @param values bigint or number combined into an array using the rest operator (`...values`)
   * @returns the minimum value from the array
   */
  min: (...values: (bigint | number)[]): bigint =>
    BigInt(values.length < 2 ? values[0] : values.reduce((a, b) => (a < b ? a : b))),
};
