import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { Address } from 'abitype';
import { cloneDeep, get, minBy, reduce } from 'lodash';
import { ContractFunctionParameters } from 'viem';
import { multicall } from 'wagmi/actions';

import { IDelegationManagerAbi } from '@layr-labs/eigen-kit/abi';
import { isEqualAddress } from '@layr-labs/eigen-kit/util';
import { UncompletedWithdrawalType } from '@layr-labs/eigen-kit/types';

import useAccount from '@/hooks/useAccount';

import { config } from 'chain/Web3Provider';
import { stakeConfig } from '@/config';

import { apiClient } from './api';
import { BIG_NUMBER_MAX } from './constants';

import type {
  token,
  UncompletedMultiAssetWithdrawals,
  UncompletedMultiAssetWithdrawalsWithToken,
  UncompletedWithdrawal,
  UncompletedWithdrawals,
  UncompletedWithdrawalsBySymbol,
  UncompletedWithdrawalsResponse,
  UncompletedWithdrawalsReturn,
  UncompletedWithdrawalsWithToken,
} from '@layr-labs/eigen-kit/types';

const minWithdrawalDelayBlocksConfig = {
  address: stakeConfig.delegationManagerAddress as `0x${string}`,
  abi: IDelegationManagerAbi,
  functionName: 'minWithdrawalDelayBlocks',
} as const;

const DEFAULT_WITHDRAWALS_RESPONSE = {
  pendingWithdrawals: [],
  completeableWithdrawals: [],
} as UncompletedWithdrawalsResponse;

const DEFAULT_TOKEN_WITHDRAWAL_DATA = {
  type: UncompletedWithdrawalType.SINGLE,
  pendingWithdrawals: [],
  pendingWithdrawalAmount: 0n,
  completableWithdrawals: [],
  completableWithdrawalAmount: 0n,
  nextCompletableWithdrawalBlock: 0n,
  completableRedelegation: [],
} as UncompletedWithdrawals;

const TOKEN_MAP_FACTORY = <R = { [strategyAddress: Address]: UncompletedWithdrawals }>({
  defaultData,
  tokenList,
}: {
  defaultData?: UncompletedWithdrawals;
  tokenList: token[];
}): R =>
  reduce(
    tokenList,
    (memo, token) => {
      memo[token.strategyAddress.toLowerCase()] = Object.assign(
        {},
        cloneDeep(defaultData ?? DEFAULT_TOKEN_WITHDRAWAL_DATA),
      );
      return memo;
    },
    {} as R,
  );

const transformPayload = ({
  pendingWithdrawals,
  completeableWithdrawals,
  tokenList,
}: {
  pendingWithdrawals: UncompletedWithdrawal[];
  completeableWithdrawals: UncompletedWithdrawal[];
  tokenList: token[];
}) => {
  const transformed = TOKEN_MAP_FACTORY({
    defaultData: DEFAULT_TOKEN_WITHDRAWAL_DATA,
    tokenList,
  });

  const _transformWithdrawal = (rootKey: 'pending' | 'completable') => {
    const amountKey = `${rootKey}WithdrawalAmount` as const;
    const withdrawalsKey = `${rootKey}Withdrawals` as const;

    return (withdrawal: UncompletedWithdrawal) => {
      const { nonce, startBlock, shares, strategies, isUndelegationQueue, ...rest } = withdrawal;

      strategies.forEach((strategy, idx) => {
        if (!get(transformed, strategy.toLowerCase())) {
          console.error(`${strategy} not whitelisted`);
          return;
        }

        const address = strategy.toLowerCase();
        transformed[address][amountKey] += BigInt(shares[idx]);
        transformed[address][withdrawalsKey].push({
          ...rest,
          isUndelegationQueue: isUndelegationQueue ?? false,
          nonce: BigInt(nonce),
          startBlock: Number(startBlock),
          shares: shares.map(BigInt),
          completableBlock: 0,
          strategies,
        } as UncompletedWithdrawal);
      });
    };
  };

  pendingWithdrawals.forEach(_transformWithdrawal('pending'));
  completeableWithdrawals.forEach(_transformWithdrawal('completable'));

  return transformed;
};

export const fetchDelayBlocksForTokens = async (tokens: token[]) => {
  const liquidDelayBlocks = await multicall(config, {
    contracts: tokens.map(
      (token) =>
        ({
          address: stakeConfig.delegationManagerAddress as `0x${string}`,
          abi: IDelegationManagerAbi,
          functionName: 'getWithdrawalDelay',
          args: [[token.strategyAddress]],
        }) as ContractFunctionParameters<
          typeof IDelegationManagerAbi,
          'view',
          'getWithdrawalDelay'
        >,
    ),
  });

  if (!liquidDelayBlocks) return [];

  return liquidDelayBlocks.map((liquidDelayBlocksRes, i) => {
    const { result: block } = liquidDelayBlocksRes as { result: bigint };
    return {
      strategy: tokens[i].strategyAddress as Address,
      withdrawalDelayBlocks: block as bigint,
    };
  });
};

export const getAllWithdrawalDataForWithdrawer = async (
  withdrawer: `0x${string}`,
): Promise<UncompletedWithdrawalsBySymbol> => {
  try {
    const tokenList = await apiClient.token.getTokens.query({
      include_native: true,
    });

    const withdrawalDataPromise = apiClient.native.podUncompletedWithdrawals
      .query({ podOwner: withdrawer })
      .catch(() => DEFAULT_WITHDRAWALS_RESPONSE)
      .then((d) => transformPayload({ ...d, tokenList }));

    const minDelayBlocksPromise = multicall(config, {
      contracts: [minWithdrawalDelayBlocksConfig],
    });

    const strategyToDelayBlockMapPromise = fetchDelayBlocksForTokens(tokenList);

    const [withdrawalData, strategyToDelayBlockMap, [{ result: minWithdrawalDelayBlock }]] =
      await Promise.all([
        withdrawalDataPromise,
        strategyToDelayBlockMapPromise,
        minDelayBlocksPromise,
      ]);

    const response = tokenList.reduce((memo, token, i) => {
      const withdrawal = withdrawalData[token.strategyAddress.toLowerCase() as Address];

      const { withdrawalDelayBlocks } = strategyToDelayBlockMap[i] ?? {
        withdrawalDelayBlocks: 0n,
        strategy: token.strategyAddress,
      };

      const delayBlock =
        withdrawalDelayBlocks && minWithdrawalDelayBlock
          ? BigInt(
              withdrawalDelayBlocks > minWithdrawalDelayBlock
                ? withdrawalDelayBlocks
                : minWithdrawalDelayBlock,
            )
          : BIG_NUMBER_MAX;

      const pendingWithdrawals = withdrawal.pendingWithdrawals.map((withdrawal) => ({
        ...withdrawal,
        completableBlock: withdrawal.startBlock + Number(delayBlock),
      }));

      const completableWithdrawals = withdrawal.completableWithdrawals.map((withdrawal) => ({
        ...withdrawal,
        completableBlock: withdrawal.startBlock + Number(delayBlock),
      }));

      const nextCompletableWithdrawalBlock = get(
        minBy<UncompletedWithdrawal>(pendingWithdrawals, ({ completableBlock }) =>
          BigInt(completableBlock ?? 0n),
        ),
        'completableBlock',
        0n,
      );

      memo[token.address] = {
        ...token,
        ...withdrawal,
        pendingWithdrawals,
        completableWithdrawals,
        nextCompletableWithdrawalBlock,
        completableRedelegation: completableWithdrawals,
      } as UncompletedWithdrawalsWithToken;

      return memo;
    }, {}) as UncompletedWithdrawalsBySymbol;

    return response;
  } catch (e) {
    return {};
  }
};

const findStrategyOrIndex = <
  T extends 'find' | 'findIndex',
  R = T extends 'find' ? Address : number,
>(
  fxn: T,
  stategiesArray: string[],
  strategy: string,
): R => stategiesArray[fxn]((s) => isEqualAddress(strategy, s))! as R;

export const findStrategy = (stategiesArray: string[], strategy: string) =>
  findStrategyOrIndex('find', stategiesArray, strategy);

export const findStrategyIndex = (stategiesArray: string[], strategy: string) =>
  findStrategyOrIndex('findIndex', stategiesArray, strategy);

const addWithdrawalToBucket = (
  keyBase: 'pendingWithdrawal' | 'completableWithdrawal',
  options: {
    uncompletedWithdrawals: UncompletedWithdrawalsWithToken;
    uncompletedWithdrawal: UncompletedWithdrawal;
    single: UncompletedWithdrawalsBySymbol;
    batches: {
      [symbols: string]: UncompletedMultiAssetWithdrawals & {
        symbols: string;
        tokens: token[];
      };
    };
  },
) => {
  const { uncompletedWithdrawals, uncompletedWithdrawal, single, batches } = options;
  const { symbol, strategyAddress, nextCompletableWithdrawalBlock } = uncompletedWithdrawals;
  const { strategies, withdrawalRoot, shares } = uncompletedWithdrawal;

  const withdrawalsKey = `${keyBase}s` as const;
  const singleAmountKey = `${keyBase}Amount` as const;
  const multiAmountKey = `${keyBase}Amounts` as const;

  try {
    if (strategies.length === 1) {
      single[symbol][withdrawalsKey].push(uncompletedWithdrawal);
      single[symbol][singleAmountKey] += BigInt(shares[0]);
      return;
    }

    const tokens = strategies
      .map((strategy: Address) =>
        stakeConfig.stakingTokenList.find((token) =>
          isEqualAddress(token.strategyAddress, strategy),
        ),
      )
      .filter(Boolean)
      .sort((a, b) => (a!.symbol.toUpperCase() > b!.symbol.toUpperCase() ? 1 : -1)) as token[];

    const sortedSymbols = tokens.map((t) => t.symbol).join('-');
    const sortedStrategies = tokens.map((t) => findStrategy(strategies, t.strategyAddress));
    const sortedShares = sortedStrategies.map((s) =>
      BigInt(shares[findStrategyIndex(strategies, s)]),
    );

    batches[sortedSymbols] ||= {
      type: UncompletedWithdrawalType.MULTI,
      tokens,
      symbols: sortedSymbols,
      strategyAddresses: sortedStrategies,
      pendingWithdrawals: [],
      pendingWithdrawalAmounts: Array(tokens.length).fill(0n),
      completableWithdrawals: [],
      completableWithdrawalAmounts: Array(tokens.length).fill(0n),
      completableRedelegation: [],
      nextCompletableWithdrawalBlock: BigInt(nextCompletableWithdrawalBlock),
    } as UncompletedMultiAssetWithdrawals & {
      symbols: string;
      tokens: token[];
    };

    const currentStrategyIndex = findStrategyIndex(sortedStrategies, strategyAddress);
    const withdrawalAlreadyPushed = batches[sortedSymbols][withdrawalsKey].find(
      (w) => w.withdrawalRoot === withdrawalRoot,
    );

    batches[sortedSymbols][multiAmountKey][currentStrategyIndex] +=
      sortedShares[currentStrategyIndex];

    if (!withdrawalAlreadyPushed) {
      batches[sortedSymbols][withdrawalsKey].push(uncompletedWithdrawal);
    }
  } catch (e) {
    console.error(e);
  }
};

export const getAllWithdrawalBucketsForWithdrawer = async (
  withdrawer: `0x${string}`,
): Promise<{
  single: UncompletedWithdrawalsBySymbol;
  batches: {
    [symbols: string]: UncompletedMultiAssetWithdrawalsWithToken;
  };
}> => {
  const single = {} as UncompletedWithdrawalsBySymbol;
  const batches: {
    [symbols: string]: UncompletedMultiAssetWithdrawalsWithToken;
  } = {};

  const allUncompletedWithdrawals = await getAllWithdrawalDataForWithdrawer(withdrawer);

  for (const uncompletedWithdrawals of Object.values(allUncompletedWithdrawals)) {
    const { symbol, strategyAddress, pendingWithdrawals, completableWithdrawals, ...rest } =
      uncompletedWithdrawals;

    single[symbol] ||= {
      ...cloneDeep(rest),
      symbol,
      pendingWithdrawals: [],
      pendingWithdrawalAmount: 0n,
      completableWithdrawals: [],
      completableWithdrawalAmount: 0n,
      completableRedelegation: [],
      strategyAddress,
    };

    const curriedAddWithdrawalToBucket =
      (keyBase: Parameters<typeof addWithdrawalToBucket>[0]) =>
      (withdrawal: UncompletedWithdrawal) =>
        addWithdrawalToBucket(keyBase, {
          uncompletedWithdrawals,
          uncompletedWithdrawal: withdrawal,
          single,
          batches,
        });

    pendingWithdrawals.forEach(curriedAddWithdrawalToBucket('pendingWithdrawal'));
    completableWithdrawals.forEach(curriedAddWithdrawalToBucket('completableWithdrawal'));

    Object.values(batches).forEach((batch) => {
      batch.completableRedelegation = [...batch.completableWithdrawals];
    });
  }

  return { single, batches };
};

export const getUncompletedWithdrawalsForStrategy = async (
  address: Address,
  strategyAddress: Address,
  blockNumber: bigint,
): Promise<UncompletedWithdrawalsReturn> => {
  const tokenList = await apiClient.token.getTokens.query({
    include_native: true,
  });

  const token = tokenList.find((token) => isEqualAddress(token.strategyAddress, strategyAddress));

  const EMPTY_WITHDRAWAL_DATA = {
    ...token,
    ...DEFAULT_TOKEN_WITHDRAWAL_DATA,
    strategyAddress,
    block: BigInt(blockNumber),
  } as UncompletedWithdrawalsReturn;

  if (!address) {
    return EMPTY_WITHDRAWAL_DATA;
  }

  try {
    const data = await getAllWithdrawalDataForWithdrawer(address);
    const withdrawData = get(data, token?.address.toLowerCase() as Address);

    if (withdrawData) {
      return {
        ...withdrawData,
        block: BigInt(blockNumber),
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }

  return EMPTY_WITHDRAWAL_DATA;
};

export const getUncompletedWithdrawalsForAllStrategies = async (address: Address) => {
  try {
    return await getAllWithdrawalDataForWithdrawer(address);
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const getAllRedelegationWithdrawals = async (
  withdrawer: Address,
): Promise<{ completableRedelegation: UncompletedWithdrawal[] }> => {
  try {
    const uncompletedWithdrawalsResp = await apiClient.native.podUncompletedWithdrawals
      .query({ podOwner: withdrawer })
      .catch(() => DEFAULT_WITHDRAWALS_RESPONSE);

    return {
      completableRedelegation: [...uncompletedWithdrawalsResp.completeableWithdrawals],
    };
  } catch {
    return {
      completableRedelegation: [],
    };
  }
};

export const useGetAllRedelegationWithdrawals = (
  queryOptions?: Partial<
    UseQueryOptions<{ completableRedelegation: UncompletedWithdrawal[] }, Error>
  >,
): UseQueryResult<{ completableRedelegation: UncompletedWithdrawal[] }, Error> => {
  const { address } = useAccount();
  const queryKey = ['getAllRedelegationWithdrawals', address];

  return useQuery<
    { completableRedelegation: UncompletedWithdrawal[] },
    Error,
    { completableRedelegation: UncompletedWithdrawal[] }
  >({
    queryKey,
    queryFn: async () => {
      if (!address) return { completableRedelegation: [] };
      return getAllRedelegationWithdrawals(address);
    },
    ...queryOptions,
  });
};

export const transformWithdrawalToStruct = (withdrawal: UncompletedWithdrawal) => ({
  staker: withdrawal.staker,
  delegatedTo: withdrawal.delegatedTo,
  withdrawer: withdrawal.withdrawer,
  nonce: withdrawal.nonce,
  startBlock: withdrawal.startBlock,
  strategies: withdrawal.strategies,
  shares: withdrawal.shares,
});
