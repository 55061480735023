import { Address } from 'abitype';
import { formatUnits } from 'viem';

import { isEqualAddress } from '@layr-labs/eigen-kit/util';
import {
  deposits,
  LiquidTokenData,
  NativeTokenData,
  sharesToUnderlyingRes,
  StakingTokenItem,
  token,
} from '@layr-labs/eigen-kit/types';

import { ETH_TO_WEI } from '../utils/constants';

const isLiquidTokenData = (data: LiquidTokenData | NativeTokenData): data is LiquidTokenData => {
  if ('tokenSharesStrats' in data && 'tokenTVL' in data && 'ethValue' in data) {
    return true;
  }

  return false;
};

export class Token implements StakingTokenItem {
  address: Address;
  strategyAddress: Address;
  slug: string;
  icon: string;
  name: string;
  symbol: string;
  decimals: number;
  rebasing: boolean;
  balance = 0n;
  mainnetAddress?: '' | Address;
  types: Array<'stakeable' | 'reward'>;
  deposited: deposits = { shares: 0n, underlying: 0n };
  apiID: string | null;
  group?: string | undefined; //NOTE: Remove undefined when configs are updated
  tvl = 0;
  usd = 0;
  marketCap = 0;
  ethValue;
  about: string;

  private _sharesToUnderlyingFactor: bigint = ETH_TO_WEI;

  constructor(token: token, data: LiquidTokenData | NativeTokenData) {
    this.address = token.address as `0x${string}`;
    this.slug = token.slug;
    this.icon = token.icon;
    this.strategyAddress = token.strategyAddress as `0x${string}`;
    this.name = token.name;
    this.symbol = token.symbol;
    this.decimals = token.decimals;
    this.rebasing = token.rebasing;
    this.apiID = token.apiID;
    this.about = token.about;
    this.group = token.group;
    this.types = token.types;
    this.marketCap = token?.marketCap || 0;
    this.mainnetAddress = token.mainnetAddress;

    if (isLiquidTokenData(data)) {
      this.initialize(data);
    } else {
      this.initializeNativeToken(data);
    }
  }

  /**
   * @private Finds the sharesToUnderlyingFactor for the token's strategy
   * @param sharesToUnderlyings An array of sharesToUnderlyingRes objects
   * @returns The sharesToUnderlyingFactor for the token's strategy
   */
  private _findTokenSharesToUnderlyingFactor(
    sharesToUnderlyings: sharesToUnderlyingRes[] = [],
  ): bigint | undefined {
    return sharesToUnderlyings.find((item) => this.matchesTokenStrategy(item?.strategy))
      ?.sharesToUnderlying;
  }

  /**
   * Getter for the sharesToUnderlyingFactor, which is a scalar used to convert
   * between shares and underlying tokens.
   * @returns The sharesToUnderlyingFactor in BigInt format
   */
  public get sharesToUnderlyingFactor(): bigint {
    return this._sharesToUnderlyingFactor;
  }

  /**
   * Setter for the sharesToUnderlyingFactor, which is a scalar used to convert
   * between shares and underlying tokens.
   * @param value Can be a BigInt, an array of sharesToUnderlyingRes objects, or
   * a single sharesToUnderlyingRes object
   * @example
   * ```ts
   * // Set the sharesToUnderlyingFactor to a BigInt
   * token.sharesToUnderlyingFactor = 1000000000000000000n;
   *
   * // Set the sharesToUnderlyingFactor to a sharesToUnderlyingRes object
   * token.sharesToUnderlyingFactor = {
   *  strategy: "0x1234...",
   *  sharesToUnderlying: 1000000000000000000n
   * };
   *
   * // Set the sharesToUnderlyingFactor to an array of sharesToUnderlyingRes objects
   * token.sharesToUnderlyingFactor = [
   *   {
   *     strategy: "0x1234...",
   *     sharesToUnderlying: 1000000000000000000n
   *   },
   *   {
   *     strategy: "0x5678...",
   *     sharesToUnderlying: 1000000000000000000n
   *   }
   * ];
   * ```
   */
  public set sharesToUnderlyingFactor(
    value: bigint | sharesToUnderlyingRes[] | sharesToUnderlyingRes | undefined,
  ) {
    let factorValue: bigint | undefined;

    if (typeof value === 'bigint') {
      factorValue = value;
    } else if (Array.isArray(value)) {
      factorValue = this._findTokenSharesToUnderlyingFactor(value);
    } else {
      factorValue = value?.sharesToUnderlying;
    }

    if (!factorValue) return;

    this._sharesToUnderlyingFactor = factorValue;
  }

  /**
   * Checks if the given address is the same as the token's strategy address
   * @param address The address to compare to the token's strategy address
   * @returns True if the address is the same as the token's strategy address, false otherwise
   */
  public matchesTokenStrategy = (address?: string) => {
    return isEqualAddress(address, this.strategyAddress);
  };

  setDeposits(shares: bigint): deposits {
    this.deposited = {
      shares: shares,
      underlying: this.convertSharesToUnderlying(shares),
    };

    return this.deposited;
  }

  setTVL(tokenTVL: bigint | number): void {
    // NOTE: If its a number, assume the shares to underlying conversion was done
    if (typeof tokenTVL === 'number') {
      this.tvl = tokenTVL;
    } else {
      const underlyingTVL = this.convertSharesToUnderlying(tokenTVL);
      this.tvl = Number(formatUnits(underlyingTVL, this.decimals));
    }
  }

  setNativeTVL(globalPodBalance: bigint): void {
    this.tvl = Number(formatUnits(globalPodBalance, this.decimals));
  }

  setUSD(price: number): void {
    this.usd = price;
  }

  setMarketCap(marketCap: number): void {
    this.marketCap = marketCap;
  }

  /**
   * Converts shares to underlying tokens
   * @param shares The number of shares to convert
   * @param opts Options for the conversion
   * @param opts.format The format to return the underlying in (decimal or uint256)
   * @param opts.string Whether to return the underlying as a string (boolean)
   * @returns The number of underlying tokens
   */
  public convertSharesToUnderlying = <
    TFormat extends 'decimal' | 'uint256' = 'uint256',
    TAsString extends boolean | undefined = false,
    TReturnType = TAsString extends true ? string : TFormat extends 'decimal' ? number : bigint,
  >(
    shares: bigint,
    opts?: { format?: TFormat; string?: TAsString },
  ): TReturnType => {
    // It seems like some shares objects are being stared as `${bigint}`s when they should just
    // be stored as bigints. We can fix this in the future, but converting them here is a temporary
    // solution.
    const value = (BigInt(shares ?? 0n) * this.sharesToUnderlyingFactor) / ETH_TO_WEI;
    if (opts?.format === 'decimal') {
      return this.formatWithDecimals(value, {
        string: opts?.string,
      }) as TReturnType;
    }

    return (opts?.string ? value.toString() : value) as TReturnType;
  };

  /**
   * Converts underlying tokens to shares
   * @param underlying The number of underlying tokens to convert
   * @param opts Options for the conversion
   * @param opts.format The format to return the shares in (decimal or uint256)
   * @param opts.string Whether to return the shares as a string (boolean)
   * @returns The number of shares
   */
  public convertUnderlyingToShares = <
    TFormat extends 'decimal' | 'uint256' = 'uint256',
    TAsString extends boolean | undefined = false,
    TReturnType = TAsString extends true ? string : TFormat extends 'decimal' ? number : bigint,
  >(
    underlying: bigint,
    opts?: { format?: TFormat; string?: TAsString },
  ): TReturnType => {
    const value = (underlying * ETH_TO_WEI) / this.sharesToUnderlyingFactor;

    if (opts?.format === 'decimal') {
      return this.formatWithDecimals(value, {
        string: opts?.string,
      }) as TReturnType;
    }

    return (opts?.string ? value.toString() : value) as TReturnType;
  };

  /**
   * Formats a large integer to a number with the token's decimals
   * @param largeInt The bigint to format
   * @param opts Options for the formatting
   * @returns The formatted number
   */
  public formatWithDecimals = <
    TAsString extends boolean = false,
    TReturnType = TAsString extends true ? string : number,
  >(
    largeInt: bigint,
    opts?: { string?: TAsString },
  ): TReturnType => {
    const value = formatUnits(largeInt, this.decimals);

    return (opts?.string ? value : Number(value)) as TReturnType;
  };

  initializeNativeToken = ({
    price,
    sharesToUnderlyings,
    userAddress,
    globalPodSummary,
    podOwnerShares,
    marketCap,
  }: NativeTokenData): void => {
    this.sharesToUnderlyingFactor = sharesToUnderlyings;

    if (userAddress) {
      this.setDeposits(podOwnerShares);
    }

    this.setNativeTVL(BigInt(globalPodSummary.balance));
    this.setUSD(price);
    this.ethValue = 1;
    this.setMarketCap(marketCap);
  };

  initialize = ({
    price = 0,
    ethValue,
    sharesToUnderlyings,
    userAddress,
    tokenSharesStrats,
    tokenTVL,
    marketCap,
  }: LiquidTokenData): void => {
    this.sharesToUnderlyingFactor = sharesToUnderlyings;

    if (userAddress) {
      const shareIdx = tokenSharesStrats.strategies.findIndex(this.matchesTokenStrategy);
      const currentRestakedShares = tokenSharesStrats.shares[shareIdx] || 0n;

      this.setDeposits(currentRestakedShares);
    }

    this.setTVL(tokenTVL);
    this.setUSD(price);
    this.ethValue = ethValue;
    this.setMarketCap(marketCap);
  };
}
