'use client';

import { useMemo } from 'react';

import { isEqualAddress } from '@layr-labs/eigen-kit/util';

import useLiquidTokens from './useLiquidTokens';
import useNativeToken from './useNativeToken';

export const useTokens = () => {
  const {
    data: liquidRestakingTokens,
    isLoading: isLiquidTokensLoading,
    isSuccess: isLiquidTokensSuccess,
  } = useLiquidTokens();
  const {
    data: nativeRestakingToken,
    isLoading: isNativeTokenLoading,
    isSuccess: isNativeTokenSuccess,
  } = useNativeToken();

  const strategyTokenMap = useMemo(() => {
    if (isLiquidTokensLoading || isNativeTokenLoading) {
      return null;
    }

    if (!nativeRestakingToken) {
      return null;
    }

    return liquidRestakingTokens.reduce(
      (acc, token) => {
        acc[token.strategyAddress.toLowerCase()] = token;
        return acc;
      },
      {
        [nativeRestakingToken?.strategyAddress.toLowerCase()]: nativeRestakingToken,
      },
    );
  }, [liquidRestakingTokens, nativeRestakingToken, isLiquidTokensLoading, isNativeTokenLoading]);

  return {
    data: {
      liquidRestakingTokens,
      nativeRestakingToken,
      strategyTokenMap,
    },
    isLoading: isLiquidTokensLoading || isNativeTokenLoading,
    isSuccess: isLiquidTokensSuccess && isNativeTokenSuccess,
    isLiquidTokensLoading,
    isLiquidTokensSuccess,
    isNativeTokenLoading,
    isNativeTokenSuccess,
  };
};

/**
 * Selects the token from the list of tokens
 * @param addressOrSymbol The address or symbol of the token
 * @returns The token object
 */
// TODO: Move this to use the DETAILS endpoint, not the LIST endpoint
//       Do as followup on M3 for longtail
export const useToken = (addressOrSymbol: string) => {
  const {
    data: nativeRestakingToken,
    isLoading: isNativeTokenLoading,
    isSuccess: isNativeTokenSuccess,
  } = useNativeToken();

  const {
    data: liquidRestakingTokens,
    isLoading: isLiquidTokensLoading,
    isSuccess: isLiquidTokensSuccess,
  } = useLiquidTokens();

  return useMemo(() => {
    if (addressOrSymbol.toUpperCase() === 'ETH') {
      return {
        data: nativeRestakingToken,
        isLoading: isNativeTokenLoading,
        isSuccess: isNativeTokenSuccess,
      };
    }

    return {
      data: liquidRestakingTokens.find(
        (token) =>
          token.slug.toUpperCase() === addressOrSymbol.toUpperCase() ||
          isEqualAddress(token.address, addressOrSymbol),
      ),
      isLoading: isLiquidTokensLoading,
      isSuccess: isLiquidTokensSuccess,
    };
  }, [
    addressOrSymbol,
    liquidRestakingTokens,
    isLiquidTokensLoading,
    isLiquidTokensSuccess,
    nativeRestakingToken,
    isNativeTokenLoading,
    isNativeTokenSuccess,
  ]);
};
