'use client';

import { useMemo } from 'react';

import { CurrentRestaked, token } from '@layr-labs/eigen-kit/types';

import useAccount from '@/hooks/useAccount';
import useFetchTokenSharesStrats from '@/hooks/useFetchTokenSharesStrats';
import usePrices, { getPriceDataFromIdentifier } from '@/hooks/usePrices';
import useQuerySharesToUnderlyingFactor from '@/hooks/useQuerySharesToUnderlying';
import useTokenTVLs from '@/hooks/useTokenTVLs';

import { api } from '@/utils/api';

import { Token } from '../classes/token';

export interface UseLiquidTokensValue {
  data: Token[];
  isLoading: boolean;
  isSuccess: boolean;
  error:
    | Error
    | ReturnType<typeof useFetchTokenSharesStrats>['error']
    | ReturnType<typeof useQuerySharesToUnderlyingFactor>['error']
    | ReturnType<typeof usePrices>['error']
    | ReturnType<typeof useTokenTVLs>['error']
    | null;
}

export const useLiquidTokens = (): UseLiquidTokensValue => {
  const { address } = useAccount();

  const { data: tokens = [], isLoading: isLoadingTokens } = api.token.getTokens.useQuery({
    include_native: false,
  });

  const {
    data: tokenSharesStrats,
    isSuccess: isTokenSharesStratsSuccess,
    isLoading: isTokenSharesStratsLoading,
    error: tokenSharesStratsError,
  } = useFetchTokenSharesStrats();

  const {
    data: underlyings,
    isSuccess: isUnderlyingsSuccess,
    isLoading: isUnderlyingsLoading,
    error: underlyingsError,
  } = useQuerySharesToUnderlyingFactor({ tokens });

  const {
    data: priceData,
    isLoading: isPriceDataLoading,
    isSuccess: isPriceDataSuccess,
    error: priceDataError,
  } = usePrices({ version: 'v1' });

  return useMemo(() => {
    let liquidRestakingTokens: Token[] = [];
    const isLoading =
      isLoadingTokens ||
      isUnderlyingsLoading ||
      isPriceDataLoading ||
      (!!address && isTokenSharesStratsLoading);
    const isSuccess =
      isUnderlyingsSuccess && isPriceDataSuccess && (!address || isTokenSharesStratsSuccess);
    const error = underlyingsError || priceDataError || tokenSharesStratsError;

    if (isSuccess) {
      liquidRestakingTokens = tokens.map((token: token): Token => {
        const price = getPriceDataFromIdentifier(token, priceData.tokenPrices, 'v1');

        return new Token(token, {
          icon: token.icon,
          price: price.usd,
          marketCap: price.usd_market_cap,
          ethValue: price.eth,
          sharesToUnderlyings: underlyings,
          userAddress: address,
          tokenSharesStrats: tokenSharesStrats as CurrentRestaked,
          tokenTVL: token.tvl ?? 0,
        });
      });
    }

    return {
      data: liquidRestakingTokens,
      isLoading,
      isSuccess,
      error,
    };
  }, [
    isLoadingTokens,
    isUnderlyingsLoading,
    isPriceDataLoading,
    address,
    isTokenSharesStratsLoading,
    isUnderlyingsSuccess,
    isPriceDataSuccess,
    isTokenSharesStratsSuccess,
    underlyingsError,
    priceDataError,
    tokenSharesStratsError,
    tokens,
    priceData?.tokenPrices,
    underlyings,
    tokenSharesStrats,
  ]);
};

export default useLiquidTokens;
