import Link from 'next/link';

import { TextV2 } from '@layr-labs/eigen-kit/react';

import type { ReactNode } from 'react';

interface NavLinkProps {
  href: string;
  children: ReactNode;
  isExternalLink?: boolean;
  className?: string;
}

function NavLink({ children, href, isExternalLink = false, className }: NavLinkProps) {
  const contents = (
    <TextV2 intent="TextM" font="ibmPlexMono" className={className}>
      {children}
    </TextV2>
  );

  return isExternalLink ? (
    <a href={href} target="_blank" rel="noreferrer">
      {contents}
    </a>
  ) : (
    <Link href={href}>{contents}</Link>
  );
}

export default NavLink;
