'use client';

import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { Address } from 'abitype';
import { useAccount as useAccountWagmi } from 'wagmi';

import useAccess from './useAccess';

const useAccount = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [impersonate, setImpersonate] = useState<Address | null>(null);
  const account = useAccountWagmi();
  const impersonationAddress = useSearchParams()?.get('impersonate') as Address;

  const address = impersonationAddress || account?.address;
  const isImpersonating = Boolean(impersonationAddress);
  const isConnected = !!address;

  const connectedWalletAccess = useAccess(address);
  const impersonationWalletAccess = useAccess(impersonationAddress);

  // NOTE: If the service returns true or null
  //       do not allow the address to interact
  //       `addressAccess` is used to block the webapplication
  const shouldDisableAddress =
    connectedWalletAccess.data === 'deny' || impersonationWalletAccess.data === 'deny';

  const isAddressRestricted =
    connectedWalletAccess.data === 'restrict' || impersonationWalletAccess.data === 'restrict';

  useEffect(() => {
    if (impersonationAddress && !shouldDisableAddress) {
      setImpersonate(impersonationAddress);
    }
  }, [impersonationAddress, shouldDisableAddress]);

  useEffect(() => {
    if (impersonate && !impersonationAddress) {
      router.push(`?impersonate=${impersonate}`);
    }
  }, [pathname, impersonate, impersonationAddress, router]);

  return {
    ...account,
    isImpersonating,
    address: shouldDisableAddress ? undefined : address,
    connectedWalletAddress: shouldDisableAddress ? undefined : account?.address,
    isConnected,
    addressAccess: connectedWalletAccess,
    isAddressRestricted,
  };
};

export default useAccount;
